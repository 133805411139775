import PropTypes from 'prop-types'
import React from 'react'
import profilepic from '../images/profilepic4.jpg'
import altruize from '../images/altruizePic.jpg';
import letsMeetThere from '../images/letsMeetThere.jpg';

class Main extends React.Component {




  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={profilepic} alt="" />
          </span>
          <p>
          Welcome to my portfolio website, I'm Scott a full stack software developer living in London and experienced in Javascript and it's related technologies; 
          React, Redux, Node.js, Gatsby, Express, PostgreSQL, Graphql and MongoDB.
          </p>
          <p>
          I'm currently looking for a full time position in and around London. If you think I may be a good fit,  don't hesitate to contact me.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projects</h2>
          <h3>Lets Meet There</h3>
          <span className="image main">
            <img src={letsMeetThere} alt="" />
          </span>
          <p>
          Designed and built a service that takes flight searches from different origins and combines them to provide you a simple one-page view of all the destinations you could meet.
          </p>
          <p>
          The app is created in React with Redux, with testing using Jest; data is leveraged from several Skyscanner Api calls.
          </p>
          <p>
          The live site was at letsmeetthere.co.uk, however  this no longer operational due to restrictions in the Skyscanner Api.
          </p>
          <p>
          Github: <a href="https://github.com/Scottburg/lets_meet_there" >github.com/Scottburg/lets_meet_there</a>
          </p>
          <h3>Altruize</h3>
          <span className="image main">
            <img src={altruize} alt="" />
          </span>
          <p>
          Altruize is a fully fledged CRUD (Create, Read, Update, Delete) service that allows registered organizations to ask for help from short term volunteers.
          </p>
          <p>
          Constructed and implemented the back-end;  designing the data flow from the SQL database through to the state management system in the front-end. 
          </p>
          <p>
          Tech stack: React, Redux, Node.js, Express.js, Sequelize, PostgreSQL and Material UI.
          </p>
          <p>
          Github: <a href="https://github.com/scottburg/Altruize">github.com/scottburg/Altruize</a> 
          </p>

          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About Me</h2>
          <span className="image main">
            {/* <img src={pic03} alt="" /> */}
          </span>
          <p>
          I'm a full-stack software developer living in London, experienced in Javascript and it's related technologies; 
          React, Typescript, Redux, Node.js, Gatsby, Express, PostgreSQL, Graphql and MongoDB. 
          </p>
          <p>  
          In a previous life I was a ACA qualified Chartered Accountant until I  realised that the most enjoyable part for me was trying to be clever/lazy automating 
          data entry via VBA macros and that to move beyond coding for microsoft excel, it was time for a career change. Since then I've done a lot of self motivated learning, 
          developed two development-ready projects and become competent to contribute with my coding skills in a full-time developer position. 
          </p>
          <p>
          In my free time I enjoy hiking, reading and going to the gym just enough to stop my body taking the exact shape as my office chair.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="POST" action="https://formspree.io/f/mdopzela" >
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">

            <li>
              <a href="https://www.linkedin.com/in/scott-burg/" className="icon fa-linkedin">
                <span className="label">LinkedIn</span>
              </a>
            </li>
    
            <li>
              <a
                href="https://github.com/scottburg"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
