import PropTypes from 'prop-types';
import React from 'react';
import cv from '../downloads/scottBurgessCV.pdf';


const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-qrcode"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Scott Burgess</h1>
        <p>
        London Based Web Developer specialising in JavaScript technologies
        </p>
        <div className="inner-links" >
        <ul className="icons">

<li>
  <a href="https://www.linkedin.com/in/scott-burg/" className="icon fa-linkedin">
    <span className="label">LinkedIn</span>
  </a>
</li>
<li>
  <a
    href="https://github.com/scottburg"
    className="icon fa-github">
    <span className="label">GitHub</span>
  </a>
</li>
</ul>
         
        </div>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Projects
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About Me
          </button>
        </li>
        <li>
          <button> 
            <a href={cv} download className="download">C.V.</a>
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
